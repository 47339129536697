$font-family-sans-serif: "Poppins", sans-serif;

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}
.auth-body-bg{
    background-color: $card-bg;
}

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
}

.auth-full-page-content{
    display: flex;
    min-height: 100vh;
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.login{
    max-width: 480px !important;
}
.bg-login{
    background: linear-gradient(0deg,white 72%,#22BF4AFF 28%);
}
.border-bottom-green{
    border-bottom: solid #22BF4A 2px !important;
}
.bg-green{
    background-color: #22BF4A !important;
}

;@import "sass-embedded-legacy-load-done:125";