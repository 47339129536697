a{
  text-decoration: none !important;
}

.btn-small {
  --bs-btn-padding-y: 0.05rem !important;
  --bs-btn-padding-x: 0.1rem !important;
  --bs-btn-font-size: 0.71094rem !important;
  --bs-btn-border-radius: 0.2rem !important;
  margin-left: 5px;
}

.btn-archive {
  --bs-btn-padding-y: 0.12rem !important;
  --bs-btn-padding-x: 0.2rem !important;
  --bs-btn-font-size: 0.71094rem !important;
  --bs-btn-border-radius: 0.2rem !important;
  margin-left: 5px;
}

.btn-w {
  width: 100%;
}

.btn-blue-radius {
  background-color: #1A65E2 !important;
  border-color: #1A65E2 !important;
  color: white !important;
  border-radius: 1rem !important;
  min-width: 4rem;
}

.btn-blue-radius:hover {
  background-color: #1A65E2CC !important;
  border-color: #1A65E2CC !important;
  color: white !important;
  min-width: 4rem;
}

.btn-green-radius {
  background-color: #22BF4AFF !important;
  border-color: #22BF4AFF !important;
  color: #D3F1DB !important;
  border-radius: 1rem !important;
}

.btn-green-radius:hover {
  background-color: #D3F1DB !important;
  border-color: #D3F1DB !important;
  color: #22BF4AFF !important;
}

.btn-green {
  background-color: #22bf4a !important;
  border-color: #22bf4a !important;
  color: white !important;
  border-radius: 0.3rem !important;
}

.btn-green:hover {
  background-color: #22BF4ACC !important;
  border-color: #22BF4ACC !important;
  color: white !important;
  border-radius: 0.3rem !important;
}

.btn-gray {
  background-color: #F5F5F5 !important;
  border-color: #F0F0F0 !important;
  color: black !important;
  border-radius: 0.3rem !important;
}

.btn-semi-bold{
  --bs-btn-font-weight: 600 !important;
}

.btn-gray:hover {
  background-color: #F0F0F0CC !important;
}

.btn-orange {
  background-color: #FBB040 !important;
  border-color: #FBB040 !important;
  color: white !important;
  border-radius: 0.3rem !important;
}

.btn-orange:hover{
  background-color: #FBB040B2 !important;
  color: white !important;
}

.btn-red {
  background-color: #f10303 !important;
  border-color: #f10303 !important;
  color: white !important;
  border-radius: 0.5rem !important;
}

.btn-red:hover {
  background-color: #F10303CC !important;
  color: white !important;
}

.btn-blue {
  background: #1a65e2;
  color: white;
}

.btn-blue:hover {
  background-color: #2F7AF6CC;
  color: white;
}

.btn-sub {
  margin-left: 1.2rem;
}

.btn-forgot {
  margin-left: 1.2rem;
}

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.btn-cdata {
  width: 85px;
  height: 35px;
  border-radius: 1rem !important;
}
;@import "sass-embedded-legacy-load-done:7";