@import "src/assets/scss/buttons";

//Veriables

$blue: #556ee6;
$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f46a6a;
$orange: #f1734f;
$yellow: #f1b44c;
$green: #34c38f;
$teal: #050505;
$cyan: #50a5f1;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$font-family-sans-serif: "Poppins", sans-serif;

// Page Font
body{
  * {
    outline: none;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-size: 16px;
  }

}

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

// Login



// Navbar
.bg-navbar-green{
  background-color: #D3F1DB !important;
}
.hover-green:hover{
  background-color: #22BF4AFF !important;
  color: #ffffff !important;
  border-radius: 0.5rem !important;
}
.dropdown.active {
  color: #00000042 !important;
}
.text-green{
  color: #22BF4A !important ;
}

// DataGrid
//.dx-selection {
//  background-color: #D3F1DB !important;
//  border-radius: 10rem!important;
//  color: #22BF4AFF !important;
//  margin: 0!important;
//  text-align: center !important;
//  width: 40px !important;
//}

.dx-pager .dx-pages .dx-page {
  min-width: 29px ;
  text-align: center;
}

.dx-selection{
  background-color: #D3F1DB !important;
  border-radius: 10rem!important;
  color: #22BF4AFF !important;
  min-width: 40.25px !important;
  text-align: center !important;
}
.dx-navigate-button {
  color: #22BF4AFF;
}
.bold {
  font-weight: bold;
}
.dx-datagrid-headers {
  background-color: #F8F9FA !important;
  color: black !important;
  font-weight: bold !important;
  border-bottom: 1px solid rgb(255, 255, 255);
  border-bottom-style: hidden !important;
  border-bottom-color: rgb(221, 221, 221);
}
.vertical-middle{
  vertical-align: middle !important;
}

// Data - Transfer

.dropzone-data{
  text-align: center;
  border: 1px black dotted !important;
  padding: 20%;
  width: 100%;
  margin: auto;
}

.cdata-text{
  font-size: 14px;
}

.dropzone-radius{
  border-radius: 1.2em !important;
  max-height: 19rem;
  min-height: 19rem;
}

//Dashboard

.dx-datagrid-headers .dx-datagrid-table .dx-row > td{
  background: #f5f5f5;
  color: #161D26 !important;
}

.down-side{
  background: #FFBF0019;
  color: #FFBF00;
  text-align: center;
  cursor: pointer;
}

.DataGrid{
  min-height: 420px;
}

.Table{
  min-height: 420px;
}

.right-aligned{
  justify-content: flex-end;
}

.trash-icon{
  color: #EB3327 !important;
}

.edit-icon{
  color: #FFBF00 !important;
}

.error-page{
  min-height: 969px;
  body{
    background: white !important;
  }
}

.profile-user-wid{
  margin-top: -26px;
}

.carousel-control-prev-icon{
background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2322BF4A%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;}


.carousel-control-next-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2322BF4A%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-indicators [data-bs-target]{
  background-color: #22bf4a !important;

}

.schedule-select .select__menu {
  position: absolute !important;
  z-index: 9999;
}

.form-footer{
  float: left !important;
  width: 100% !important;
  display: block !important;
}

.pointer{
  cursor: pointer;
}

// DROPZONE

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
};

.thumb {
  //border-radius: 2px;
  //border: 1px solid #eaeaea;
  margin-bottom: 8px;
  //margin-right: 8px;
  //width: 24%;
  //padding: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
};

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
};

.thumbImg{
  display: block;
  width: auto;
  height: 100%;
};
